import React, {Component} from 'react';
import {
    Button,
    Input
} from 'reactstrap';

import 'react-dropzone-uploader/dist/styles.css'

import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import AddAttachment from "../Dispatch/AddAttachment";

class ComposeMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            attachments: [],
            default_attachments: [],
            processing: false
        };
    }

    componentDidMount() {
        if (typeof this.props.default_message != "undefined" && typeof this.props.default_message != this.state.message) {
            this.setState({
                message: this.props.default_message,
                attachments: this.props.default_attachments,
                default_attachments: this.props.default_attachments
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (typeof this.props.default_message != "undefined" && prevProps.default_message !== this.props.default_message && typeof this.props.default_message != this.state.message) {
            this.setState({
                message: this.props.default_message,
                attachments: this.props.default_attachments,
                default_attachments: this.props.default_attachments
            })
        }
    }

    attachmentCallback = (details) => {
        var attachments = this.state.attachments;
        attachments.push(details.path);
        this.setState({
            attachments: attachments
        });
    };

    updateField = (name, value) => {
        if (typeof value != "string" && typeof value.target != "undefined") {
            value = value.target.value;
        }
        this.setState({
            [name]: value
        });
    };

    sendMessage = async event => {
        if (this.props.driver_id == null) {
            Swal("Error", "Please select a driver", "error");
        } else if (this.state.message == "" && this.state.attachments.length == 0) {
            Swal("Error", "Please enter a message or attachment", "error");
        } else {
            this.setState({
                processing: true
            });
            var self = this;
            axios.defaults.withCredentials = true;
            axios.post(API_ROOT + '/messages', {driver_id: this.props.driver_id, body: this.state.message, attachments: this.state.attachments, mode: this.props.mode})
                .then(function (response) {
                    self.setState({
                        message: "",
                        attachments: [],
                        processing: false
                    });
                    if (typeof self.props.newMessageCallback != "undefined") {
                        self.props.newMessageCallback();
                    }
                })
                .catch(function (error) {
                    if (typeof error.response != "undefined") {
                        if (error.response.status === 401) {
                            self.props.userSignOut()
                        } else {
                            Swal("Error", error.response.data.Message, "error");
                            self.setState({
                                processing: false
                            });
                        }
                    }
                });
        }
    };

    render() {
        return (
            <>
                <Input type="textarea" maxLength={320} style={{height: "120px"}} size="lg" name="message" placeholder="Type message here" value={this.state.message}
                       onChange={(event) => this.updateField("message", event)}/>
                {this.state.attachments.map(function (attachment, attachment_num) {
                    return (
                        <a href={attachment} target="_blank"><img
                            src={attachment} className="img-fluid rounded p-1"
                            style={{maxWidth: "150px", maxHeight: "300px"}} alt="Click to View Attachment"
                            title="Click to View"/></a>
                    )
                }, this)}
                <AddAttachment {...this.state} attachmentCallback={this.attachmentCallback}/>
                <Button color="primary" className="mt-2" disabled={this.state.processing} onClick={(event) => this.sendMessage()}><i className="far fa-paper-plane"></i> Send
                    Message</Button>
            </>
        );
    };
}

export default (ComposeMessage);
